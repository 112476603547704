import React, { useState, useContext } from "react";
import { Link } from "gatsby";
import { FirebaseContext } from "../components/Firebase";

import Seo from "../components/seo";
import Header from "../components/organisms/Header";
import Logo from "../components/atoms/Logo";
import Loader from "../components/atoms/Loader";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

const ForgotPasswordPage = () => {

  const [formValues, setFormValues] = useState({
    email: ''
  });
  const { firebase } = useContext(FirebaseContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [resetSent, setResetSent] = useState(false);
  const [loading, setLoading] = useState(false);

  // handle submit 
  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    firebase.resetPassword({
      email: formValues.email
    }).then(() => {
      setLoading(false);
      setResetSent(true);
      setSuccessMessage('Please check your inbox for a password reset link.');
    }).catch(error => {
      setLoading(false);
      setErrorMessage(error.message);
    });
  }

  // handle input change
  const handleInputChange = (e) => {
    e.persist();
    setErrorMessage('');
    setSuccessMessage('');
    setFormValues(currentValues => ({
      ...currentValues,
      [e.target.name]: e.target.value
    }));
  }

  return (
    <>
      <Seo title="Forgot Password" />
      <Header classes="header--hasLogo">
        <Logo />
      </Header>
      <main className="static main--hasLogo">
        <div className="container">
          {!!loading ? (
            <Loader />
          ) : (
            <>
              {!!resetSent ? (
                <>
                  <div className="information">
                    <h1>Password reset link sent</h1>
                  </div>
                  {!!successMessage &&
                    <div className="message message--success">
                      <div className="icon"><FontAwesomeIcon icon={ faCheck } /></div>
                      <span>{successMessage}</span>
                    </div>
                  }
                  <Link className="btn btn--primary" to="/">Login</Link>
                </>
              ) : (
                <>
                  <div className="information">
                    <h1>Forgot your password?</h1>
                    <p>Enter your email address to retrieve your password.</p>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="input-group">
                      <label htmlFor="email">Email Address</label>
                      <input onChange={handleInputChange} value={formValues.email} name="email" type="email" placeholder="Email Address" required />
                    </div>
                    {!!errorMessage &&
                      <div className="message message--error">
                        <div className="icon"><FontAwesomeIcon icon={ faTimes } /></div>
                        <span>{errorMessage}</span>
                      </div>
                    }
                    <button className="btn btn--primary" type="submit">Reset Password</button>
                    <div className="description description--center"><small>Remembered your password? <Link className="link link--primary" to="/">Login here.</Link></small></div>
                  </form>
                </>
              )}
            </>
          )}
        </div>
      </main>
    </>
  )
}

export default ForgotPasswordPage;